<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">

    <search-quotes :quotes="quotes" @add="addQuoteSidebar" :isSidebarActive="showQuotesSidebar"
                   @closeSidebar="showQuotesSidebar= false"/>

    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Nuevo comprobante de venta</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">

        <!--Quotes-->
        <div>
          <vs-button @click="showQuotesSidebar= true" :disabled="progress" class="w-full" color="danger"
                     type="border">
            Buscar cotización
          </vs-button>
        </div>

        <!-- Client and Quote -->
        <div class="vx-row mt-5">
          <div class="vx-col w-1/2">
            <vs-input class="w-full" label="Cotización*" v-model="document.quote.code" disabled/>
          </div>
          <div class="vx-col w-1/2">
            <div>
              <vs-input class="w-full" label="Cliente*" v-model="document.client.businessName" disabled/>
            </div>
          </div>
        </div>
        <!--End-->

        <!--Message-->
        <div class="mt-5" v-if="document.client.retainer ==='true'">
          <span class="bg-danger rounded min-w-full border text-white block p-2 text-center">Cliente es retenedor</span>
        </div>
        <!--End-->

        <!--Divider-->
        <vs-divider class="mt-5"></vs-divider>
        <!--End-->

        <!---Currency, Decimals and IGV-->
        <div class="vx-row mt-5">
          <div class="vx-col w-1/3">
            <div>
              <vs-input class="w-full" label="Moneda*" v-model="document.currency.value" disabled/>
            </div>
          </div>
          <div class="vx-col w-1/3">
            <div>
              <label class="text-sm opacity-75">IGV*</label>
              <v-select @input="calculateTotal" :options="igvTypes"
                        :clearable="false"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        v-model="document.igvType" class="mb-4 md:mb-0" name="igvType" id="igvType"
                        v-validate="'required'"/>
              <span class="text-danger text-sm"
                    v-show="errors.has('igvType')">{{ errors.first('igvType') }}</span>
            </div>
          </div>
          <div class="vx-col w-1/3">
            <div>
              <vs-input class="w-full" label="Tipo de redondeo*" v-model="document.roundingType" disabled/>
            </div>
          </div>
        </div>
        <!--End-->

        <!---Regime--->
        <div class="vx-row mt-5">
          <div class="vx-col w-1/2">
            <label class="text-sm opacity-75">Tipo de regimen*</label>
            <v-select @input="calculateTotal" :options="regimeTypes"
                      :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="document.regimeType" class="mb-4 md:mb-0" name="regimeType" id="regimeType"
                      v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('regimeType')">{{ errors.first('regimeType') }}</span>
          </div>
          <div class="vx-col w-1/2">
            <div>
              <vs-input class="w-full" label="Email*" v-model="document.client.email"/>
            </div>
          </div>
        </div>
        <!--End-->

        <!--Payment type-->
        <div>
          <ul class="demo-alignment">
            <li>
              <vs-radio v-model="document.paymentType" vs-name="paymentTypeNew" vs-value="regular">Regular</vs-radio>
            </li>
            <li>
              <vs-radio v-model="document.paymentType" vs-name="paymentTypeNew" vs-value="fees">Cuotas</vs-radio>
            </li>
          </ul>
        </div>
        <!--End-->

        <!--Payment method-->
        <template v-if="document.paymentType === 'regular'">
          <div class="vx-row mt-5">
            <div class="vx-col w-1/2">
              <label class="text-sm opacity-75">Forma de pago*</label>
              <v-select :options="paymentMethods"
                        label="value"
                        :clearable="false"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        v-model="document.paymentMethod" class="mb-4 md:mb-0" name="paymentMethod" id="paymentMethod"
                        v-validate="'required'"/>
              <span class="text-danger text-sm"
                    v-show="errors.has('paymentMethod')">{{ errors.first('paymentMethod') }}</span>
            </div>
          </div>
        </template>
        <!--End-->

        <!--Fees and frequency-->
        <div v-else-if="document.paymentType === 'fees'" class="vx-row mt-5">
          <div class="vx-col w-1/2">
            <div>
              <label class="text-sm opacity-75">Cuotas*</label>
              <v-select :options="amountOfFees"
                        label="value"
                        :clearable="false"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        v-model="document.fees"
                        class="mb-4 md:mb-0"
                        name="fees"
                        id="fees"
                        v-validate="'required'"/>
              <span class="text-danger text-sm"
                    v-show="errors.has('fees')">{{ errors.first('fees') }}</span>
            </div>
          </div>
          <div class="vx-col w-1/2">
            <label class="text-sm opacity-75">Frecuencia*</label>
            <v-select :options="frequencies"
                      label="value"
                      :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="document.frequency" class="mb-4 md:mb-0"
                      v-validate="'required'"
                      name="frequency"
                      id="frequency"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('frequency')">{{ errors.first('frequency') }}</span>
          </div>
        </div>
        <!--End-->

        <!--Divider-->
        <vs-divider class="mt-5"></vs-divider>

        <!--Observation-->
        <div class="mt-5">
          <vs-textarea v-validate="'max:255'" rows="2" name="observation" label="Observación"
                       v-model="document.observation"/>
          <span class="text-danger text-sm"
                v-show="errors.has('observation')">{{ errors.first('observation') }}</span>
        </div>
        <!--End-->

        <!--Added products-->
        <vs-table class="mt-8" :data="addedProducts">

          <template slot="thead">
            <vs-th>Descripción</vs-th>
            <vs-th>Nro. de Parte</vs-th>
            <vs-th>Precio ({{ document.quote ? document.currency.symbol : '' }})</vs-th>
            <vs-th>Cantidad</vs-th>
            <vs-th>Total</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">

              <vs-td style="width: 40%" :data="tr.description">
                <vs-textarea disabled rows="5" v-model="tr.description"></vs-textarea>
              </vs-td>

              <vs-td :data="tr.numberPart">
                <p> {{ tr.numberPart }}</p>
              </vs-td>

              <vs-td :data="tr.price">
                <p>
                  {{
                    Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: document.currency.code,
                      minimumFractionDigits: document.roundingType,
                      maximumFractionDigits: document.roundingType,
                    }).format(tr.price)
                  }}
                </p>
              </vs-td>

              <vs-td :data="tr.quantity">
                <template>
                  <p> {{ tr.quantity }}</p>
                </template>
              </vs-td>

              <vs-td :data="tr.total">
                <template>
                  <p v-if="document.igvType === 'No incluye IGV'">
                    {{
                      Intl.NumberFormat('en-IN', {
                        style: 'currency',
                        currency: document.currency.code,
                        minimumFractionDigits: document.roundingType,
                        maximumFractionDigits: document.roundingType,
                      }).format(tr.price * tr.quantity)
                    }}
                  </p>
                  <p v-else>
                    {{
                      Intl.NumberFormat('en-IN', {
                        style: 'currency',
                        currency: document.currency.code,
                        minimumFractionDigits: document.roundingType,
                        maximumFractionDigits: document.roundingType,
                      }).format(tr.price * tr.quantity * 1.18)
                    }}
                  </p>
                </template>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <!---->

        <!--Divider-->
        <vs-divider class="mt-5"></vs-divider>

        <!--Amounts-->
        <div class="vx-row">
          <div class="vx-col w-3/4"></div>
          <div class="vx-col w-1/4">
            <div class="flex flex-wrap items-center justify-between">
              <span class="font-bold">Sub total: </span>
              <span>
                {{
                  Intl.NumberFormat('en-IN', {
                    style: 'currency',
                    currency: document.currency.code ? document.currency.code : 'PEN',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(total / 1.18)
                }}
              </span>
            </div>
            <!--Divider-->
            <vs-divider class="mt-5"></vs-divider>
            <div class="flex flex-wrap items-center justify-between">
              <span class="font-bold">IGV: </span>
              <span>
                {{
                  Intl.NumberFormat('en-IN', {
                    style: 'currency',
                    currency: document.currency.code ? document.currency.code : 'PEN',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format((total / 1.18) * 0.18)
                }}
              </span>
            </div>
            <!--Divider-->
            <vs-divider class="mt-5"></vs-divider>
            <div class="flex flex-wrap items-center justify-between">
              <span class="font-bold">Total: </span>
              <span>
                {{
                  Intl.NumberFormat('en', {
                    style: 'currency',
                    currency: document.currency.code ? document.currency.code : 'PEN',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(total)
                }}
              </span>
            </div>
          </div>
        </div>
        <!--End-->
      </div>
    </component>
    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Generar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from '../../mixins/trimString'
import getQuote from '@/mixins/quotes'
import vSelect from 'vue-select'
import SearchQuotes from '@/views/purchases/SearchQuotes'

import { db, FieldValue } from '@/firebase/firebaseConfig'
import axios from 'axios'

import departments from '../../data/ubigeo/departments.json'
import provinces from '../../data/ubigeo/province.json'
import districts from '../../data/ubigeo/district.json'
import ubigeo from '../../data/ubigeo/ubigeo.json'

const _ = require('lodash')

export default {
  name: 'NewSale',
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    quotes: {
      type: Array,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar,
    vSelect,
    SearchQuotes
  },
  mixins: [trimString, getQuote],
  data () {
    return {
      document: {
        currency: {},
        quote: {},
        client: {},
        paymentMethod: {},
        igvType: null,
        regimeType: null,
        paymentType: 'regular',
        fees: null,
        frequency: null
      },
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false,
      addresses: [],
      contacts: [],
      currencies: [
        {
          id: 1,
          value: 'Soles',
          symbol: 'S/',
          code: 'PEN'
        },
        {
          id: 2,
          value: 'Dólares américanos',
          symbol: '$',
          code: 'USD'
        }
      ],
      igvTypes: [
        'No incluye IGV',
        'Incluye IGV'
      ],
      regimeTypes: [
        {
          label: 'Sin regimen',
          percentage: 0
        },
        {
          label: 'Retención',
          percentage: 3
        },
        {
          label: 'Detracción (10%)',
          percentage: 10
        },
        {
          label: 'Detracción servicios (12%)',
          percentage: 12
        }
      ],
      paymentMethods: [
        {
          id: 7,
          value: 'Crédito a 7 días'
        },
        {
          id: 30,
          value: 'Crédito a 30 días'
        },
        {
          id: 15,
          value: 'Crédito a 15 días'
        },
        {
          id: 20,
          value: 'Crédito a 20 días'
        },
        {
          id: 45,
          value: 'Crédito a 45 días'
        },
        {
          id: 60,
          value: 'Crédito a 60 días'
        },
        {
          id: 90,
          value: 'Crédito a 90 días'
        },
        {
          id: 1,
          value: '50% Adelantado 50% contra entrega'
        },
        {
          id: 31,
          value: '50% Adelantado 50% a 30 dias'
        },
        {
          id: 0,
          value: 'Contado'
        }
      ],
      // Sidebars
      showNewProviderSidebar: false,
      showAddressSidebar: false,
      showContactSidebar: false,
      showNewProductSidebar: false,
      showQuotesSidebar: false,
      addedProducts: [],
      selected: null,
      total: 0,
      // Fees
      amountOfFees: [
        {
          id: 2,
          value: '2 Cuotas'
        },
        {
          id: 3,
          value: '3 Cuotas'
        },
        {
          id: 4,
          value: '4 Cuotas'
        }
      ],
      frequencies: [
        {
          id: 15,
          value: 'Cada 15 días'
        },
        {
          id: 30,
          value: 'Cada 30 días'
        }
      ],
      // End
      // Ubigeo
      departments: departments,
      provinces: provinces,
      districts: districts,
      ubigeo: ubigeo,
      paymentType: 'regular'
    }
  },
  watch: {
    isSidebarActive (val) {
      if (val) {
        this.document = {
          currency: {},
          quote: {},
          client: {},
          paymentMethod: {},
          igvType: null,
          regimeType: {
            label: 'Sin regimen',
            percentage: 0
          },
          paymentType: 'regular',
          fees: null,
          frequency: null
        }
        this.addedProducts = []
        this.$validator.reset()
      }
    },
    addedProducts: {
      deep: true,
      handler () {
        this.calculateTotal()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    /**
     * Save data in firestore
     * @returns {Promise<void>}
     */
    async submitData () {
      try {
        const result = await this.$validator.validateAll()
        if (result) {
          if (this.total !== 0) {
            this.progress = true
            // Calculate total
            const total = this.total
            const subTotal = parseFloat((this.total / 1.18).toFixed(2))
            const igv = parseFloat((total - subTotal).toFixed(2))
            // Get sequence
            let code = ''
            let sequence = ''
            let serie = ''
            if (this.document.client.typeDocument === 'RUC') {
              const response = await axios.get('https://southamerica-east1-anglidata-1aa70.cloudfunctions.net/sequences-getSequencesForInvoices', {
                headers: {
                  'Authorization': 'Bearer bd14b377-92f6-4741-8303-1f372131897c'
                }
              })
              code = 'FFF1-' + response.data.sequence
              sequence = response.data.sequence
              serie = 'FFF1'
            } else {
              const response = await axios.get('https://southamerica-east1-anglidata-1aa70.cloudfunctions.net/sequences-getSequencesForTickets', {
                headers: {
                  'Authorization': 'Bearer bd14b377-92f6-4741-8303-1f372131897c'
                }
              })
              code = 'BBB1-' + response.data.sequence
              sequence = response.data.sequence
              serie = 'BBB1'
            }
            // End
            // Get exchange rate
            const responseExchange = await axios.get('https://southamerica-east1-anglidata-1aa70.cloudfunctions.net/exchangeRate-getExchangeRate', {
              headers: {
                'Authorization': 'Bearer 78b1deb4-1b27-4943-9d83-818285cc59d6'
              }
            })
            // End
            let obj = {
              ...this.document,
              state: true,
              status: 'hold',
              products: [
                ...this.addedProducts
              ],
              total,
              subTotal,
              igv,
              user: this.document.quote.user || null,
              code,
              type: 'regular',
              sequence,
              serie,
              paidOut: 0,
              dueDate: new Date(new Date().setDate(new Date().getDate() + this.document.paymentMethod.id)),
              exchangeRate: responseExchange.data.venta + +0.025
            }
            // Filter Products
            obj.filterProducts = []
            obj.products.forEach((p) => {
              obj.filterProducts.push(p.numberPart)
            })
            // End
            // Save in firestore
            const doc = await db.collection('sales').add({
              ...obj,
              createdAt: FieldValue.serverTimestamp()
            })
            // Add sale products to quote
            this.document.quote.products.forEach((qp) => {
              const product = this.addedProducts.find((pp) => pp.id === qp.id && pp.description.trim() === qp.description.trim())
              if (product) {
                qp.invoice += product.quantity
              }
            })
            // Update Quote status and products
            await db.collection('quotes').doc(this.document.quote.id).update({
              status: 'invoiced',
              products: this.document.quote.products
            })
            obj.id = doc.id
            obj.createdAt = new Date()

            // Send to nubefact
            const responseNubefact = await this.sendNubefact(sequence, total, subTotal, igv, serie, responseExchange.data.venta)
            if (responseNubefact !== -1) {
              await db.collection('sales').doc(doc.id).update({
                nubefact: {
                  ...responseNubefact.nubefact
                },
                status: responseNubefact.nubefact.aceptada_por_sunat ? 'send' : 'not_send'
              })
              // Open PDF in browser
              window.open(responseNubefact.nubefact.enlace_del_pdf, 'blank')
              obj.status = responseNubefact.nubefact.aceptada_por_sunat ? 'send' : 'not_send'
            }
            obj.nubefact = {
              ...responseNubefact.nubefact
            }
            // End

            this.$emit('closeSidebar')
            this.$emit('add', obj)
            this.$vs.notify({
              color: 'success',
              title: 'Comprobante de venta',
              text: 'Comprobante creado correctamente.'
            })
          } else {
            this.$vs.notify({
              color: 'warning',
              title: 'Comprobante de venta',
              text: 'Revisa cantidad y precio de productos.'
            })
          }
        }
      } catch (e) {
        this.$emit('closeSidebar')
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error'
        })
      } finally {
        this.progress = false
      }
    },
    /**
     * Search quote sidebar
     */
    async addQuoteSidebar (o) {
      try {
        this.showQuotesSidebar = false
        this.progress = true
        this.addedProducts = []
        const quote = await this.getQuote(o.id)

        this.document.quote = _.cloneDeep(quote)
        this.document.quote.label = this.document.quote.code + ' - ' + this.document.quote.client.businessName
        this.document.igvType = this.document.quote.igvType
        this.document.currency = this.document.quote.currency
        this.document.client = this.document.quote.client
        this.document.roundingType = this.document.quote.roundingType
        this.document.paymentMethod = this.document.quote.paymentMethod
        this.document.paymentType = this.document.quote.paymentType
        this.document.fees = this.document.quote.fees || {}
        this.document.frequency = this.document.quote.frequency || {}
        this.document.paymentType = this.document.quote.paymentType || 'regular'

        console.log(this.document.paymentType, 'Aquiiii')

        this.document.quote.products.forEach((p) => {
          const obj = {
            id: p.id,
            sku: p.sku,
            numberPart: p.numberPart,
            price: p.price,
            quantity: p.guide - p.invoice,
            description: p.description
          }
          if (obj.quantity > 0) {
            this.addedProducts.push(obj)
          }
        })

        await this.validateRetentionAgents()

        if (this.addedProducts.length === 0) {
          this.$vs.notify({
            color: 'warning',
            title: 'Atención',
            text: 'Cotización no tiene productos pendientes de facturar.'
          })
          this.document.quote = {}
          this.document = {
            currency: {},
            quote: {},
            client: {},
            paymentMethod: {},
            paymentType: 'regular',
            igvType: null,
            regimeType: {
              label: 'Sin regimen',
              percentage: 0
            }
          }
        }
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error'
        })
      } finally {
        this.progress = false
      }
    },
    /**
     * Calculate total
     */
    calculateTotal () {
      this.total = 0
      this.addedProducts.forEach((p) => {
        if (this.document.igvType === 'No incluye IGV') {
          this.total += parseFloat((p.quantity * p.price * 1.18).toFixed(2))
        } else {
          this.total += parseFloat((p.quantity * p.price * 1.18).toFixed(2))
        }
      })
      this.total = parseFloat(this.total.toFixed(2))
    },
    /**
     * Send nubefact
     */
    async sendNubefact (sequence, total, subTotal, igv, serie, exchangeRate) {
      try {
        const items = []
        this.addedProducts.forEach((p) => {
          items.push(
              {
                'unidad_de_medida': 'NIU',
                'codigo': p.numberPart,
                'codigo_producto_sunat': '32130000',
                'descripcion': p.description,
                'cantidad': p.quantity,
                'valor_unitario': p.price,
                'precio_unitario': parseFloat((p.price * 1.18).toFixed(this.document.roundingType)),
                'descuento': '',
                'subtotal': parseFloat((p.price * p.quantity).toFixed(this.document.roundingType)),
                'tipo_de_igv': 1,
                'igv': parseFloat((p.price * p.quantity * 1.18).toFixed(this.document.roundingType)) - parseFloat((p.price * p.quantity).toFixed(this.document.roundingType)),
                'total': parseFloat((p.price * p.quantity * 1.18).toFixed(this.document.roundingType)),
                'anticipo_regularizacion': false,
                'anticipo_documento_serie': '',
                'anticipo_documento_numero': ''
              }
          )
        })
        // Get guides
        const guides = []
        this.document.observation = this.document.observation ? ', OC. Cliente: ' : 'OC. Cliente: '
        const querySnap = await db.collection('guides').where('quote.id', '==', this.document.quote.id).where('status', '==', 'send').get()
        querySnap.forEach((doc) => {
          guides.push({
            'guia_tipo': '1',
            'guia_serie_numero': doc.data().code
          })
          if (doc.data().purchaseOrder) {
            this.document.observation += doc.data().purchaseOrder.trim() + ', '
          }
        })
        // End
        // Split emails
        const clientDoc = await db.collection('clients').doc(this.document.client.id).get()
        const emails = clientDoc.data().email !== '_' ? clientDoc.data().email.split(',') : []
        // End
        const document = {
          'operacion': 'generar_comprobante',
          'tipo_de_comprobante': this.document.client.typeDocument === 'RUC' ? 1 : 2,
          'serie': serie,
          'numero': sequence,
          'sunat_transaction': 1,
          'cliente_tipo_de_documento': this.document.client.typeDocument === 'RUC' ? '6' : '1',
          'cliente_numero_de_documento': this.document.client.document + '',
          'cliente_denominacion': clientDoc.data().businessName,
          'cliente_direccion': clientDoc.data().address + '-' + clientDoc.data().department + '-' + clientDoc.data().province + '-' + clientDoc.data().district,
          'fecha_de_emision': new Date().getDate() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getFullYear(),
          'moneda': this.document.currency.id,
          'porcentaje_de_igv': 18,
          'total_gravada': subTotal,
          'total_igv': igv,
          'total': total,
          'observaciones': this.document.observation,
          'enviar_automaticamente_a_la_sunat': true,
          'enviar_automaticamente_al_cliente': true,
          'formato_de_pdf': '',
          'guias': [
            ...guides
          ],
          'medio_de_pago': this.document.paymentMethod.id !== 0 || this.document.paymentType !== 'regular' ? 'CRÉDITO' : 'CONTADO',
          'condiciones_de_pago': this.document.paymentMethod.value.toUpperCase(),
          'tipo_de_cambio': exchangeRate,
          'cliente_email': emails.length >= 1 ? emails[0].trim() : '',
          'cliente_email_1': emails.length >= 2 ? emails[1].trim() : '',
          'cliente_email_2': emails.length >= 3 ? emails[2].trim() : ''
        }

        // Bills
        if (this.document.paymentType === 'regular') {
          if (this.document.paymentMethod.id !== 0) {
            let billTotal = 0
            if (this.document.regimeType.percentage === 0) {
              billTotal = total
            }
            else {
              billTotal = total - (total * this.document.regimeType.percentage / 100)
            }
            let dueDate = new Date(new Date().setDate(new Date().getDate() + this.document.paymentMethod.id))
            document.venta_al_credito = [{
              cuota: '1',
              importe: billTotal,
              fecha_de_pago: dueDate.getDate() + '-' + (dueDate.getMonth() + 1) + '-' + dueDate.getFullYear()
            }]
            document.fecha_de_vencimiento = dueDate.getDate() + '-' + (dueDate.getMonth() + 1) + '-' + dueDate.getFullYear()
          }
          else {
            let dueDate = new Date(new Date().setDate(new Date().getDate() + 1))
            document.fecha_de_vencimiento = dueDate.getDate() + '-' + (dueDate.getMonth() + 1) + '-' + dueDate.getFullYear()
          }
        }
        else {
          let billTotal = 0
          if (this.document.regimeType.percentage === 0) {
            billTotal = total
          }
          else {
            billTotal = total - (total * this.document.regimeType.percentage / 100)
          }
          document.venta_al_credito = []
          let dueDate = null
          for (let i = 1; i <= this.document.fees.id; i++) {
            dueDate = new Date(new Date().setDate(new Date().getDate() + (this.document.frequency.id * i)))
            document.venta_al_credito.push({
              cuota: i + '',
              importe: this.toFixedFloor(billTotal / this.document.fees.id, 2),
              fecha_de_pago: dueDate.getDate() + '-' + (dueDate.getMonth() + 1) + '-' + dueDate.getFullYear()
            })
          }
          document.fecha_de_vencimiento = dueDate.getDate() + '-' + (dueDate.getMonth() + 1) + '-' + dueDate.getFullYear()
        }
        // End

        // Regime
        if (this.document.regimeType.percentage !== 0) {
          if (this.document.regimeType.percentage === 3) {
            document.retencion_tipo = 1
            document.retencion_base_imponible = total
            document.total_retencion = parseFloat((total * 3 / 100).toFixed(2))
          }
          else if (this.document.regimeType.percentage === 12 || this.document.regimeType.percentage === 10) {
            document.sunat_transaction = 30
            document.detraccion = true
            document.detraccion_tipo = 35
            document.detraccion_total = this.document.currency.id === 1 ? (total * (this.document.regimeType.percentage / 100)) : ((total * (this.document.regimeType.percentage / 100)) * exchangeRate)
            document.detraccion_porcentaje = this.document.regimeType.percentage
            document.medio_de_pago_detraccion = 1
          }
        }
        const response = await this.$http.post('https://southamerica-east1-anglidata-1aa70.cloudfunctions.net/nubefact-sendInvoices', {
          document,
          items
        }, {
          headers: {
            'Authorization': 'Bearer bd14b377-92f6-4741-8303-1f372131897c'
          }
        })
        return response.data
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: e.response.data.errors,
          time: 7000
        })
        return -1
      }
    },
    /**
     *
     * @param num
     * @param decimalPlaces
     * @returns {number}
     */
    toFixedFloor (num, decimalPlaces) {
      const factor = Math.pow(10, decimalPlaces);
      return Math.floor(num * factor) / factor;
    },
    /**
     * Validate retention agents
     * @returns {Promise<void>}
     */
    async validateRetentionAgents () {
      try {
        const response = await this.$http.get('https://southamerica-east1-anglidata-1aa70.cloudfunctions.net/vendor-validateRetentionAgents?document=' + this.document.client.document, {
          headers: {
            Authorization: 'Bearer bd14b377-92f6-4741-8303-1f372131897c'
          }
        })

        if (response.data.EsAgenteRetencion) {
          this.document.client.retainer = 'true'
        }
        else {
          this.document.client.retainer = 'false'
        }
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.add-new-client-header {
  padding: 8px;
  color: green;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    height: 15px;
    margin-right: 4px;
  }

  &:hover {
    background-color: rgba(green, 0.12);
  }
}

.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 80vw;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
