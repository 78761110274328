<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Buscar cotización</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div id="data-list-list-view" class="p-6">
        <!--Products-->
        <vs-table ref="table" pagination search :max-items="itemsPerPage" v-model="selected"
                  responsive
                  class="mt-5"
                  :data="quotes">
          <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
            <!-- Items per page -->
            <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
              <div
                  class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{
                currentPage * itemsPerPage - (itemsPerPage - 1)
              }} - {{ quotes.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : quotes.length }} de {{
                queriedItems
              }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
              </div>
              <vs-dropdown-menu>

                <vs-dropdown-item @click="itemsPerPage=10">
                  <span>10</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage=20">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage=30">
                  <span>30</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage=40">
                  <span>40</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
          <!--Head-->
          <template slot="thead">
            <vs-th>Nro. Cotización</vs-th>
            <vs-th>Cliente</vs-th>
            <vs-th>Estado</vs-th>
            <vs-th>Acciones</vs-th>
          </template>
          <!--Body-->
          <template slot-scope="{data}">
            <tbody>
            <vs-tr v-for="(tr, indextr) in data" :data="tr" :key="indextr">
              <vs-td>
                <p>{{ tr.code }}</p>
              </vs-td>

              <vs-td>
                <p> {{ tr.client.businessName }}</p>
              </vs-td>

              <vs-td>
                <vs-chip :color="getStatusColor(tr.status)" class="product-order-status">
                  {{ getStateText(tr.status) | title }}
                </vs-chip>
              </vs-td>

              <vs-td>
                <vs-button @click="$emit('add', tr)">Seleccionar</vs-button>
              </vs-td>
            </vs-tr>
            </tbody>
          </template>
        </vs-table>
        <!---->
      </div>
    </component>
    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cerrar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  name: 'SearchQuotes',
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    quotes: {
      type: Array,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar
  },
  data () {
    return {
      document: {},
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false,
      selected: {},
      isMounted: false,
      itemsPerPage: 25
    }
  },
  watch: {
    isSidebarActive (val) {
      if (val) {
        this.document = {}
        this.$validator.reset()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    },
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.quotes.length
    }
  },
  mounted () {
    this.isMounted = true
  },
  methods: {
    /**
     * Get status color
     * @param status
     * @returns {string}
     */
    getStatusColor (status) {
      if (status === 'hold') return 'warning'
      if (status === 'inProcess') return 'dark'
      if (status === 'rejected') return 'danger'
      return 'success'
    },
    /**
     * Get state text
     * @param value
     * @returns {string}
     */
    getStateText (value) {
      if (value === 'hold') return 'Generada'
      if (value === 'inProcess') return 'En proceso'
      if (value === 'rejected') return 'Anulada'
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 60vw !important;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
