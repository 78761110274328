<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Registro de movimientos - {{ this.document.code }}</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component id="divPrint" :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings"
               :key="$vs.rtl">
      <div class="p-6">
        <div v-if="progress" class="mt-4 mb-4" style="width: 100%">
          <vs-progress indeterminate color="primary"></vs-progress>
        </div>
        <div>
          <vs-button @click="exportToExcel" color="dark" type="border" class="w-full mb-8">Descargar</vs-button>
        </div>

        <!--Purchases--->
        <div class="flex flex-col gap-4">
          <div>
            <span>Total de compras en soles: PEN </span>
            <span>{{
                movements.reduce((accumulator, currentValue) => {
                  if (currentValue.type === 'purchase' && currentValue.currency.code === 'PEN') {
                    return accumulator + currentValue.total
                  }
                  else {
                    return accumulator
                  }
                }, 0)
              }}</span>
          </div>
          <div>
            <span>Total de compras en dólares: USD </span>
            <span>{{
                movements.reduce((accumulator, currentValue) => {
                  if (currentValue.type === 'purchase' && currentValue.currency.code === 'USD') {
                    return parseFloat((accumulator + currentValue.total).toFixed(2))
                  }
                  else {
                    return accumulator
                  }
                }, 0)
              }}</span>
          </div>
        </div>
        <!--End-->

        <!--List-->
        <div class="mt-8">
          <vs-table class="mt-5" :data="movements">
            <template slot="thead">
              <vs-th>Nro Doc.</vs-th>
              <vs-th>Tipo</vs-th>
              <vs-th>Fecha</vs-th>
              <vs-th>Total</vs-th>
              <vs-th>Ver</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <!--Type doc-->
                <vs-td>
                  <p v-if="tr.type === 'purchase' || tr.type === 'delivery'  || tr.type === 'sale' || tr.type === 'credit' || tr.type === 'transferIn' || tr.type === 'transferOut'">
                    {{ tr.document }}
                    <template v-if="tr.type === 'purchase'">({{ tr.provider.businessName }})</template>
                  </p>
                  <p v-else>{{ tr.code }}</p>
                </vs-td>
                <!--End-->

                <vs-td>
                  <p> {{ getType(tr.type) }}</p>
                </vs-td>

                <vs-td>
                  <p> {{ tr.createdAt | date(true) }}</p>
                </vs-td>

                <vs-td v-if="tr.currency">
                  <p>{{
                      Intl.NumberFormat('en-IN', {
                        style: 'currency',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        currency: `${tr.currency.code}`
                      }).format(tr.total)
                    }}</p>
                </vs-td>
                <vs-td v-else>
                  <p>PEN 0</p>
                </vs-td>

                <vs-td>
                  <feather-icon @click="openLink(tr.link)" class="mr-2" title="Ver link" icon="ExternalLinkIcon"
                                svgClasses="w-5 h-5 hover:text-primary stroke-current cursor-pointer"
                  />
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
    </component>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from '../../mixins/trimString'
import openLink from '@/mixins/link'

import { db } from '@/firebase/firebaseConfig'

const _ = require('lodash')

export default {
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    isSidebarActive: {
      type: Boolean,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar
  },
  mixins: [trimString, openLink],
  data () {
    return {
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      document: {},
      movements: [],
      progress: false
    }
  },
  watch: {
    async isSidebarActive (val) {
      if (!val) return
      this.document = {
        ...this.data
      }
      await this.getMovements(this.document.quote)
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    /**
     * Get type movements
     */
    getType (type) {
      if (type === 'purchase') {
        return 'Orden de compra'
      } else if (type === 'delivery') {
        return 'Guía de remisión'
      } else if (type === 'sale') {
        return 'Factura de venta'
      } else if (type === 'credit') {
        return 'Nota de crédito'
      } else if (type === 'quote') {
        return 'Cotización'
      }
    },
    /**
     * Get movements
     */
    async getMovements (quote) {
      try {
        this.progress = true
        this.movements = []
        const querySnapshot = await db.collection('quotes').doc(quote.id)
            .collection('quotesMovements')
            .orderBy('createdAt', 'desc').get()
        querySnapshot.forEach((doc) => {
          if (doc.data().type !== 'sale') {
            this.movements.push({
              id: doc.id,
              ...doc.data(),
              createdAt: doc.data().createdAt.toDate()
            })
          }
        })
        this.movements.push({
          code: quote.code,
          type: 'quote',
          currency: quote.currency,
          total: quote.total,
          createdAt: quote.createdAt,
          link: quote.link
        })
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.progress = false
      }
    },
    /**
     * Exports to excel
     */
    exportToExcel () {
      import('@/vendor/Export2Excel').then(excel => {
        const list = _.cloneDeep(this.movements)
        list.forEach((i) => {
          i.type = this.getType(i.type)
          i.date = this.$options.filters.date(i.createdAt, true)
          i.currency = i.currency ? i.currency.value : 'NA'
        })
        const headerVal = ['document', 'type', 'date', 'total', 'currency']
        const headerTitle = ['Nro. Doc', 'Tipo', 'Fecha', 'Total', 'Moneda']
        const data = this.formatJson(headerVal, list)
        excel.export_json_to_excel({
          header: headerTitle,
          data,
          filename: 'Registro de movimientos - ' + this.document.code,
          autoWidth: this.cellAutoWidth,
          bookType: 'xlsx'
        })
      })
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        // Add col name which needs to be translated
        // if (j === 'timestamp') {
        //   return parseTime(v[j])
        // } else {
        //   return v[j]
        // }

        return v[j]
      }))
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 850px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
